import {
  createRouter,
  createWebHistory
} from 'vue-router'

// 模拟一个简单的认证检查函数
function isAuthenticated() {
  // 这里可以添加实际的认证逻辑，例如检查 JWT token 或 session
  return !!localStorage.getItem('authToken');
}


const routes = [{
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: {
      title: '叽咪文化传媒',
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue'),
    meta: {
      title: '登录到 JIMIID'
    }
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('../views/ListView.vue'),
    meta: {
      title: '叽咪文化传媒'
    }
  },
  {
    path: '/wework',
    name: 'wework',
    component: () => import('../views/Login/Wework.vue'),
    meta: {
      title: 'JIMIID Wework'
    }
  },


]

const router = createRouter({
  history: createWebHistory(),
  routes
})

// 全局导航守卫
router.beforeEach((to, from, next) => {
  // 设置页面标题
  if (to.meta.title) {
    document.title = to.meta.title;
  }

  // 检查需要登录的页面
  if (to.meta.requiresAuth && !isAuthenticated()) {
    const currentPath = encodeURIComponent(to.fullPath); // 对当前路径进行编码
    const pathstate = currentPath
    next({
      name: 'login',
      query: {
        state: pathstate
      }
    }); // 跳转到登录页面
  } else {
    next(); // 继续导航
  }
})

export default router